
    .sku-list {
        // margin-top: 20px;
        
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        padding: 4px 4px;
        .header {
            line-height: 36px;
            font-size: 16px;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 5px;
        }
        .item {
            // display: inline-block;
            height: 30px;
            line-height: 30px;
        }
    }
@primary-color: #1890ff;