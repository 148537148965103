.case-manager.mode-edit {
    border-top: 4px solid @primary-color;
}

.case-manager {
    // min-height: 100vh;
    height: 100%;
    border-top: 4px solid transparent;
    display: flex;
    flex-direction: column;
    .case-detail {
        flex: 1;
        overflow-y: auto;
    }
    .footer {
        // background-color: #333;
        // position: fixed;
        // bottom: 0;
        // left: 0;
        // width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 0 10px;
        padding-top: 5px;
    }
    
}

@primary-color: #1890ff;