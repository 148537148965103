
.app-header {
    display: flex;
    padding: 0;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    // background-color: #3277ff;
    background-color: #fff;
    // colior
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    top: 0;
    z-index: 9;
    .app-header-prefix {
        width: @scrollContentWidth;
        line-height: 40px;
        white-space: nowrap;
        overflow-x: hidden;
        .logo {
            display: flex;
            align-items: center;
            padding: 0 0 0 8px;
            img {
                position: relative;
                top: 1px;
                margin-right: 5px;
            }
            .zh-CN {
                font-size: 18px;
                position: relative;
            }
            .version {
                display: inline-block;
                color: white;
                margin-left: -2px;
                margin-top: -5px;
                border-radius: 3px;
                background-color: #505050;
                height: 18px;
                line-height: 18px;
                transform: scale(0.7);
                .label {
                    display: inline-block;
                    // height: 16px;
                    // line-height: 16px;
                    background-color: #505050;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    padding: 0 4px;
                    font-size: 12px;
                    transform: scale(0.8);
                }
                .value {
                    display: inline-block;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    padding: 0 4px;
                    font-size: 12px;
                }
                .value.prod {
                    background-color: lightgreen;
                }
                .value.pre {
                    background-color: @primary-color;
                }
                .value.dev {
                    background-color: orange;
                }
            }
        }
        
        
    }
    .entries,
    .home-guide {
        height: 28px;
        margin-top: 6px;
        padding: 0 10px 0 0;
        .button {
            height: 24px;
            line-height: 24px;
            margin-top: 2px;
            padding: 0 10px;
            border-radius: 3px;
            background-color: #f4f6f7;
            color: #666;
            cursor: pointer;
        }
        .button:hover {
            background-color: #e4e6e7;
        }
    } 
    .home-guide {
        border-left: 1px solid #eee;
        padding-left: 10px;
    }
    .ant-menu-horizontal {
        line-height: 40px;
    }
    .app-header-suffix {
        line-height: 40px;
        padding: 0 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 320px;
        .profile {
            font-size: 14px;
            cursor: pointer;
            padding: 0 8px;
            line-height: 40px;
            .img-wrapper {
                height: 24px;
                width: 24px;
                border-radius: 24px;
                margin-right: 5px;
            }
        }
        .more {
            cursor: pointer;
            padding: 0 8px;
        }
    }
}