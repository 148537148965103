.case-editor {
    // padding: 5px 5px;
    // position: relative;
    // display: flex;
.ant-drawer.ant-drawer-open {
    height: 165px !important;
}
    .title {
        font-size: 18px;
        padding: 5px 0;
    }
    
    .bind-confirm {
        // background-color: rgba(0, 0, 0, .8);
        padding: 10px;
        width: 600px;
        min-height: 50px;
        border-radius: 5px;
        position: fixed;
        margin-left: 110px;
        bottom: 10px;
        z-index: 999;
        display: flex;
        .content {
            flex: 1;
            border-radius: 5px;
            // background-color: rgba(0, 0, 0, .8);
            margin-right: 10px;
            padding: 10px;
            line-height: 20px;
            font-size: 16px;
        }
    }
    .case-detail {
        // background-color: #111;
        min-height: 300px;
        // width: 910px;
        // padding: 10px;
        .area-devices {
            display: flex;

            .area-list {
                // background-color: #111;
                width: 100px;
                
            }
            .diagram {
                // width: 660px;
                flex: 1;
            }
            .scene-edit {
                width: 120px;
                // padding: 0 10px 0 10px;
                .add-scene {
                    vertical-align: top;
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    border: 3px dashed rgba(0, 0, 0, .3);
                    color: rgba(0, 0, 0, .3);
                    border-radius: 10px;
                    font-size: 16px;
                    cursor: pointer;
                    margin: 5px;
                }
                .add-scene:hover {
                    border: 3px dashed rgba(0, 0, 0, .5);
                    color: rgba(0, 0, 0, .6);
                    .anticon-plus {
                        color: rgba(0, 0, 0, .6) !important;
                    }
                }
                .scene-list {
                    .scene-item {
                        margin: 5px;
                        vertical-align: top;
                        display: inline-block;
                        width: 100px;
                        height: 100px;
                        border: 1px solid rgba(0, 0, 0, .5);
                        color: rgba(0, 0, 0, .6);
                        border-radius: 10px;
                        font-size: 16px;
                        cursor: pointer;
                        // background-color: #111;
                        .scene-name {
                            
                        }
                    }
                }
            }
        }
    }
    .case-description {
        flex: 1;
        background-color: #111;
        margin-left: 5px;
    }
    .first-area-tip {
        // background-color: rgba(0, 0, 0, .4);
        border: 1px solid rgba(255, 255, 255, .2);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .area-item {
        cursor: pointer;
        text-align: center;
        padding: 8px 0;
        margin-bottom: 5px;
    }
    .area-item.active {
        background-color: @primary-color;
        // color: #fff;
    }

   
    .diagram {
        // background-color: #000;
    }
}
.ant-drawer {
    
    .ant-drawer-body {
        padding: 0;
        padding-top: 0;
        .ant-tabs {
            overflow: visible;
            // border-top: 1px solid @primary-color;
        }
    }
}
.prod-bar {
    white-space: nowrap;
    overflow-x: scroll;
    .prod-item {
        display: inline-block;
        // background-color: #333;
        
        border-radius: 3px;
        padding: 10px 8px 0 8px;
        margin-right: 8px;
        cursor: pointer;
        text-align: center;
        border: 2px solid transparent;
        span {
            display: inline-block;
            margin-top: 5px;
        }
       
    } .prod-item:hover {
        border: 2px solid @primary-color;
    }
}
.prod-bar::-webkit-scrollbar {
    // width: 0;
    // background-color: #1f1f1f;
}
.prod-bar::-webkit-scrollbar-button {
    // background-color: #000;
    width: 0;
}
.prod-bar::-webkit-scrollbar-thumb {
    // background-color: #333;
}

.node-add-cell {
    border: 2px dashed rgba(255, 255, 255, .3);
    border-radius: 12px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.node-add-cell:hover {
    border: 2px dashed rgba(255, 255, 255, .5);
    .anticon-plus {
        color: rgba(255, 255, 255, .6) !important;
    }

}

.scene-and-speaker-node-cell,
.four-button-switch-node-cell,
.three-button-switch-node-cell,
.curtain-motor-node-cell,
.curtain-control-node-cell,
.plug_in-card-power-node-cell {
    padding: 5px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    // background-color: #535250;
    margin: 10px 10px;
    .prod-name {
        margin-top: 5px;   
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        align-items: center;
        .anticon {
            color: lightgreen !important;
            margin-right: 4px;
        }
    }
}

.only-connector {
    width: 190px;
    height: 190px;
    border-radius: 3px;
    background: #767674;
    display: flex;
    justify-content: center;
    align-items: center;
}
.two-switch,
.one-switch,
.three-switch{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .switch-item {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 190px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-top: 0;
        border-bottom: 0;
        color: lightgray;
        background-color: #767674;
        margin-bottom: 9px;
    }
    .switch-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .switch-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.six-switch>div {
    height: 95px;
    width: 190px;
    .switch-item {
        display: flex;
        justify-content: center;
        color: lightgray;
        background-color: #767674;
        width: calc(190px / 3);
    }
}
// .curtain-control-node-cell {
//         background-color: #767674;

// }

// .two-switch {
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
//     .switch-item {
//         display: flex;
//         flex: 1;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//         height: 180px;
//         border: 1px solid rgba(0, 0, 0, .1);
//         border-top: 0;
//         border-bottom: 0;
//         color: lightgray;
//         background-color: #767674;
//         margin-bottom: 9px;
//     }
//     .switch-item:first-child {
//         border-top-left-radius: 3px;
//         border-top-left-radius: 3px;
//     }
//     .switch-item:last-child {
//         border-bottom-right-radius: 3px;
//         border-bottom-right-radius: 3px;
//     }
// }

// .curtain-control-node-cell {
//     .three-switch {
//         .switch-item {
//             height: 160px;
//         }
//     }
// }

// .scene-and-speaker-node-cell {
//     .four-switch {
//         .switch-item {
//             height: 180px;
//         }
//     }
// }
// .three-button-switch-node-cell {
    
//     .one-switch,
//     .two-switch,
//     .three-switch,
//     .four-switch {
//         .switch-item {
//             height: 180px;
//         }
//     }
// }

.four-switch {
    font-size: 0;
    height: 190px;
    .switch-item {
        display: inline-block;
        width: 92px;
        background-color:#767674;//#767674;
        justify-content: center;
        align-items: center;
        height: 95px;
        
    }
    .switch-item:first-child {
        border-top-left-radius: 3px;
        border-right: 1px solid rgba(0, 0, 0, .1);
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    .switch-item:nth-child(2) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-top-right-radius: 3px;
    }
    .switch-item:nth-child(3) {
        border-bottom-left-radius: 3px;
        position: relative;
        top: -2;
    }
    .switch-item:last-child {
        border-bottom-right-radius: 3px;
        border-left: 1px solid rgba(0, 0, 0, .1);
    }
}

.update-scene-content,
.create-scene-content {
    .selector {
        flex: 1;
        margin-right: 10px;
    }
    .result {
        padding-left: 10px;
        width: 150px;
        border-left: 1px dashed rgba(255, 255, 255, .1);
    }
    .scene-app-item {
        padding: 5px 3px;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        margin-bottom: 5px;
    }
    .scene-description {
        .close {
            display: none;
            cursor: pointer;
        }
    }
    .scene-description:hover {
        .close {
            display: inline-block;
        }
        .anticon {
            color: lightgray !important;
        }
    }

}

.node-cell {
    border: 3px solid rgba(0, 0, 0, 0)
}

.node-cell:hover {
    border: 3px solid #1890ffaa
}

.offline-speaker-list {
    // background-color: #111;
        
    .offline-speaker-item {
        cursor: pointer;
        text-align: center;
        padding: 8px 12px;
        margin-bottom: 5px;
    }
    .offline-speaker-item.active {
        background-color: @primary-color;
        // color: #fff;
    }
}

.info-channel-item {
    border-radius: 10px; 
    display: inline-block;
    height: 20px;
    width: 20px !important;
    background-color: #aaa;
    margin: 0 3px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
}
.info-channel-item.active {
    background-color: @primary-color;
}


.info-channel-item2 {
    border-radius: 3px; 
    display: inline-block;
    padding: 0px 12px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    color: #888;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
}
.info-channel-item2:not(:last-child) {
    margin-right: 5px;
}
.info-channel-item2.none {
    opacity: 0.3;
    cursor: not-allowed;
}
.info-channel-item2.disabled {
    background-color: #bbb;
    color: #fff;
    border-color: #aaa;
    cursor: not-allowed;
}
.info-channel-item2.active {
    background-color: @primary-color;
    color: #fff;
    border-color: @primary-color;
}

.config-actions {
    overflow-x: scroll;
    padding: 0 20px;
    .action-item {
        display: inline-block;
        margin-right: 20px;
        margin-top: 5px;
        text-align: center;
        cursor: pointer;
        .display {
            position: relative;
            height: 80px;
            width: 80px;
            border-radius: 8px;
            background-color: #333;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            .anticon {
                color: @primary-color;
                
            }
        }
    }
    .action-item.disabled {
        cursor: not-allowed;
    }
    .action-item:hover {
        .display {
            background-color: #444;        
        }
    }
    
}

.config-actions::-webkit-scrollbar {
    // width: 0;
    background-color: #1f1f1f;
}
.config-actions::-webkit-scrollbar-button {
    // background-color: #000;
    width: 0;
}
.config-actions::-webkit-scrollbar-thumb {
    background-color: #333;
}

.small-font{    
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.5);
}
@primary-color: #1890ff;