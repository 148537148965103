.app-header {
  display: flex;
  padding: 0;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
  z-index: 9;
}
.app-header .app-header-prefix {
  width: 200px;
  line-height: 40px;
  white-space: nowrap;
  overflow-x: hidden;
}
.app-header .app-header-prefix .logo {
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
}
.app-header .app-header-prefix .logo img {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.app-header .app-header-prefix .logo .zh-CN {
  font-size: 18px;
  position: relative;
}
.app-header .app-header-prefix .logo .version {
  display: inline-block;
  color: white;
  margin-left: -2px;
  margin-top: -5px;
  border-radius: 3px;
  background-color: #505050;
  height: 18px;
  line-height: 18px;
  transform: scale(0.7);
}
.app-header .app-header-prefix .logo .version .label {
  display: inline-block;
  background-color: #505050;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0 4px;
  font-size: 12px;
  transform: scale(0.8);
}
.app-header .app-header-prefix .logo .version .value {
  display: inline-block;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 4px;
  font-size: 12px;
}
.app-header .app-header-prefix .logo .version .value.prod {
  background-color: lightgreen;
}
.app-header .app-header-prefix .logo .version .value.pre {
  background-color: #1890ff;
}
.app-header .app-header-prefix .logo .version .value.dev {
  background-color: orange;
}
.app-header .entries,
.app-header .home-guide {
  height: 28px;
  margin-top: 6px;
  padding: 0 10px 0 0;
}
.app-header .entries .button,
.app-header .home-guide .button {
  height: 24px;
  line-height: 24px;
  margin-top: 2px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f4f6f7;
  color: #666;
  cursor: pointer;
}
.app-header .entries .button:hover,
.app-header .home-guide .button:hover {
  background-color: #e4e6e7;
}
.app-header .home-guide {
  border-left: 1px solid #eee;
  padding-left: 10px;
}
.app-header .ant-menu-horizontal {
  line-height: 40px;
}
.app-header .app-header-suffix {
  line-height: 40px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 320px;
}
.app-header .app-header-suffix .profile {
  font-size: 14px;
  cursor: pointer;
  padding: 0 8px;
  line-height: 40px;
}
.app-header .app-header-suffix .profile .img-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  margin-right: 5px;
}
.app-header .app-header-suffix .more {
  cursor: pointer;
  padding: 0 8px;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.app-content-layout {
  position: relative;
  z-index: 99;
  margin-left: -17px;
}
.app-content {
  overflow: auto;
  flex: 1;
}
