
.sider {
    // margin-right: 5px;
    display: flex;
    height: calc(100vh - 40px);
    width: 200px;
    flex-direction: column;
    // background-color: #252526;
    .tools {
        padding: 8px;
        height: 62px;
    }
    .menus {
        flex: 1;
        // overflow-y: scroll;
    }
    
    .menus::-webkit-scrollbar {
        width: 0;
    }
    .version {
        height: 30px;
        line-height: 30px;
        text-align: center;
    }
}
.sider-tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, .2);
    background-color: rgba(255, 255, 255, .1);
    border-radius: 3px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    .anticon {
        font-size: 18px;
    }
    .name {
        line-height: 14px;
        font-size: 10px;
    }
}


.sider-tool:hover {
    background-color: rgba(255, 255, 255, .2);
}
@primary-color: #1890ff;