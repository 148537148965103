.hex-download {
    // display: flex;
    // justify-content: center;
    .files {
        // width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .file-item {
        position: relative;
        display: flex;
        width: 600px;
        justify-content: space-between;
        border: 1px solid gray;
        padding: 5px 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    .progress-bar {
        position: absolute;
        height: 100%;
        background: rgba(200, 200, 255, 0.5);
        top: 0px;
        left: 0px;
        border-radius: 10px;
        
    }
}
@primary-color: #1890ff;