.page-home {
    display: flex;
    justify-content: center;
    align-items: center;
    .apps {
        display: flex;
        .app {
            text-align: center;
            line-height: 120px;
            margin: 0 10px;
            font-size: 48px;
            cursor: pointer;
            width: 264px;
            padding: 10px 50px;
            border: 2px solid gray;
            color: gray;
        }
        .app:hover {
            background-color: @primary-color;
            color: white;
            border-color: white;
        }
    }
}

.page-full {
    height: 100vh !important;
    width: 100vw;
}
@primary-color: #1890ff;