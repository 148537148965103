@keyframes redshining
{
    0% {
        background: rgba(252, 85, 49, 0)
    }
    25% {
        background: rgba(252, 85, 49, 0.5)
    }
    50% {
        background: rgba(252, 85, 49, 1)
    }
    75% {
        background: rgba(252, 85, 49, 0.5)
    }
    100% {
        background: rgba(252, 85, 49, 0)
    }
}
 
@keyframes greenshining 
{
    0% {
        background: rgba(111, 206, 127, 0)
    }
    25% {
        background: rgba(111, 206, 127, 0.5)
    }
    50% {
        background: rgba(111, 206, 127, 1)
    }
    75% {
        background: rgba(111, 206, 127, 0.5)
    }
    100% {
        background: rgba(111, 206, 127, 0)
    }
}

.red-shining {
    animation: redshining 6s infinite;
}
.green-shining {
    animation: greenshining 6s infinite;

}

.info-list {
    .tag {
        background-color: rgba(0, 0, 0, .01);
        height: 18px;
        line-height: 18px;
        padding: 0 6px;
        border-radius: 3px;
        margin-right: 10px;
        color: #888;
    }
}

.scene-list {
    .tag {
        background-color: rgba(0, 0, 0, .07);
        padding: 0 5px;
        margin-right: 10px;
        border-radius: 3px;
        transform: scale(0.9);
    }
}

.area-item { 
    display: inline-block;
    padding: 5px 16px;
    border-radius: 8px;
    background-color: #eee;
    margin-right: 10px;
    cursor: pointer;
}
.area-item.bg-primary {
    background-color: @primary-color !important;
}
.area-item.area-add {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #888;
}
.area-item.area-add:hover {
    border-color: @primary-color;
    color: @primary-color;
}

.case-node-item {
    display: flex;
    .label {
        width: 5em;
        text-align: right;
    }
}
@primary-color: #1890ff;