.page-case-config {
    .prod-code,
    .prod-name,
    .prod-mac {
        color: #333;
    }

    .name {
        font-size: 16px;
    }

    .node-cell-connector  {
        display: none !important;
        .ant-btn {
            display: none;
        }
    }

}
@primary-color: #1890ff;