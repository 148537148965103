.case-node-row {
    .item {
        cursor: pointer;
        border: 2px solid #fff;
    }
    .item.active {
        background-color: rgba(21, 192, 244, 0.2);

    }
    .item:not(.active):hover {
        // border-color: lightblue;
        background-color: rgba(21, 192, 244, 0.1);
    }   
}
@primary-color: #1890ff;