.case-editor-page {
    .customer {
        border-radius: 3px;
    }
    .customer:hover {
        // background-color: rgba(0, 0, 0, .05);
    }
    .product-item:hover {
        background-color: #f6f6ff;
    }
}

@primary-color: #1890ff;