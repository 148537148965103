.page-login {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-spin-nested-loading {
        height: auto;
    }
    .qr-code-wrapper {
        padding: 30px;
        background-color: #fff;
        color: gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@primary-color: #1890ff;