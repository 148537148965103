.hex-writer {
    .progress {
        position: absolute;
        background-color: green;
        height: 100%;
    }
    .tip {
        position: relative;
        z-index: 9;
        width: 100%;
        color: #fff;
    }
    .line {
        color: #fff;
        font-size: 16px;
        line-height: 20px;
    }
    .line.color-green {
        color: lightgreen;
    }
}
@primary-color: #1890ff;