.hex-manager-page {
    min-width: 1000px;
    section>.title {
        font-size: 20px;
        line-height: 28px;
        color: gray;
        padding: 16px 24px 12px 24px;
    }
    .branch-current-version-item {
        margin-bottom: 12px;;
    }
    .ant-card-head {
        padding:  0 14px;
    }
    .ant-card-body {
        padding: 14px !important;
    }
    .version-name {
        font-size: 16px; 
        margin-bottom: 12px;
    }
    .more {
        display: flex;
        margin-top: 34px;
        .versions {
            width: calc(100vw - 200px - 2 * 17px - 400px);
            min-width: calc(1000px - 200px - 2 * 17px - 400px);
            margin-right: 10px;
            background-color: #141414;
            padding: 0 14px;
            box-sizing: border-box;
        }
        .files {
            width: 400px;
            margin-left: 10px;
            background-color: #141414;
            margin-right: 5px;
        }
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
        width: 100px !important;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
        left: 120px !important;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        left: 120px !important;
        width: calc(100% - 160px);
    }
    .record-file-list {
        padding: 0 10px;
        cursor: pointer;
    }

    .record-file-list>.title {
        color: gray !important;
        font-size: 16px !important;
    }

    .record-file-item {
        border: 1px dashed lightgray;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 3px 8px;
        line-height: 24px;
    }

    .group-stage-item:not(:first-child) {
        border-top: 1px dashed lightgray;
        padding-top: 10px;
    }
}
@primary-color: #1890ff;