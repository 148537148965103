.page-outbound,
.page-inbound2 {

    .ant-card-grid:hover {
        background-color: @primary-color;
        color: #fff;
    }
    
    .ant-table-thead > tr > th {
        background: #fff;
        border-bottom: 1px solid #f2f2f7;
        color: #333;
    }
    
    .ant-table-tbody > tr > td {
        color: #333;
        background-color: #fefefe;
        border-bottom: 1px solid #fdfdfd;
    
    }
    
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-color: #f2f2f2;
        color: #333;
    
    }
    
    .ant-empty-normal {
        color: #333;
    }
    
    .ant-table-row.active {
        .ant-table-cell {
            background-color: @primary-color;
            color: #fff;
        }
    }
    
    .ant-table-row.first {
        .ant-table-cell {
            font-size: 18px;
            font-weight: bold;
        }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background-color: #fff;
        color: #333;
    }
    

}


.ant-steps-item-icon {
    font-size: 16px;
    line-height: 28px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
}
.ant-steps-item-icon, .ant-steps-item-content {
    vertical-align: middle;
    margin-left: 5px;
}
@primary-color: #1890ff;