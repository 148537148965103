.connector.source {
    border: 3px solid rgb(6, 157, 245);
}
.connector.source:hover {
    border-color:  rgb(6, 205, 245);;
}
.connector.source.checked {
    background-color: rgb(6, 157, 245);
}
.connector.source.checked:hover {
    background-color:  rgb(6, 205, 245);;
}


.connector.target {
    border: 3px solid rgb(224, 176, 34);
}
.connector.target:hover {
    border-color:  rgb(220, 240, 6);
}
.connector.target.checked {
    background-color: rgb(224, 176, 34);
}
.connector.target.checked:hover {
    background-color:  rgb(220, 240, 6);
}


.wireless {
    cursor: pointer;
}
.not-wireless {
    cursor: pointer;
    color: pink;
    font-weight: bold;
}
@primary-color: #1890ff;