.project-manager-home-page {
    .customer-item {
        border: 2px solid #fff;
        cursor: pointer;
    }
    .customer-item.active {
        border-color: @primary-color;
    }
    .customer-item:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    }
}
@primary-color: #1890ff;