.spec-select {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 4px 4px;
    .spec-item {
        padding: 10px 0 5px 0;
        .spec-name {
            width: 4rem;
            text-align: right;
            float: left;
            padding-right: 10px;
        }
        .spec-values {
            border-left: 1px solid lightgray;
            padding-left: 10px;
            margin-left: 4rem;
            // background-color: red;
        }
        .spec-value {
            display: inline-block;
            padding: 0px 7px;
            border: 1px solid lightgray;
            border-radius: 3px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    .spec-item:not(:first-child){
        border-top: 1px dashed lightgray;
    }
    .spec-view {
        // display: flex;
        // .spec {
        //     width: 100px;
        //     border-right: 1px solid #d9d9d9;
        // }
        // .spec-values {
        //     flex: 1;
        //     margin-left: 15px;
        //     .spec-value {
        //         margin-right: 15px;
        //     }
        // }
    }
    // .spec-item, .spec-value-item {
    //     border-bottom: 1px dotted #dadada;
    //     height: 34px;
    //     display: flex;
    //     align-items: center;
    // }
    // .spec-item {
    //     justify-content: flex-end;
    //     // padding-right: 10px;
    //     margin-right: 15px;
    // }
    // .spec-item.active {
    //     // background-color: @primary-color;
    //     color: @primary-color;
    // }
    // .spec-item.active::before {
    //     content: '√';
    // }
    .tip {
        color: red;
        // font-size: 12px;
        padding-top: 5px;
    }


}
@primary-color: #1890ff;