body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  /* min-width: 1200px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*::before,*::after {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex1,
.flex-1 {
  flex: 1;
}
.flex {
  display: flex;
}
.inline-block {
  display: inline-block;
}

html, body {
  height: 100vh;
  width: 100vw;
}


/* .page-full {
  height: 100vh;
  width: 100vw;
} */

.inline-block {
  display: inline-block;
}



.page-full {
  height: 100vh;
  width: 100vw;
}
.bg-white {
  background-color: #fff;
}
.inline-block {
  display: inline-block;
}

* {
  box-sizing: border-box;
}

.page-full 
  .content-wrapper {    
      display: flex; 
      flex-direction: column;
      flex: 1;
  }

.flex1 { 
  flex: 1;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.none {
  display: none;
}


.img-wrapper {
  
  /* display: inline-block; */
  overflow: hidden;
}

.img-wrapper img {
  height: 100%;
  width: 100%;
  vertical-align: top;
}