.case-list {
    .case-item {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 3px;
        .title {
            font-size: 14px;
            margin-bottom: 3px;
        }
        .tag {
            background-color: rgba(0, 0, 0, .1);
            height: 18px;
            line-height: 18px;
            padding: 0 6px;
            border-radius: 3px;
            transform: scale(0.8);
        }
    }
    .case-item:hover {
        background-color: #e6f7ff;
        .title {
            color: @primary-color;
        }
    }
    .case-item.active {
        background-color: @primary-color;
        color: #fff;
        .tag {
            color: #ddd;
        }
    }
    .case-item.active:hover {
        .title {
            color: #fff;
        }
    }
}
@primary-color: #1890ff;